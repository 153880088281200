<template>
    <n-list value="merchants" text="商戶" :fields="fields" :items="merchants" />
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            fields: [
                {
                    key: 'code',
                    label: '代號',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: '名稱',
                    sortable: true,
                },
            ]
        }
    },

    created() {
        this.$store.dispatch('bindCollection', { col: 'merchants' })
    },

    computed: {
        ...mapState({
            merchants: state => state.merchants,
        })
    }
}
</script>